import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Grid,
  GridItem,
  Heading,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Page, Loader, Retry } from '../../../components';
import { rootwalletActions } from '../../../redux/actions';

const RootWallet = () => {
  const { darkMode } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const { content, status, loading, error } = useSelector(
    (state) => state.rootwalletConfig,
  );

  const objectParser = (obj) => {
    if (obj.constructor.name === 'Object') {
      return true
    } else {
      return false
    }
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 6, // (this suffices for whole numbers, but will print 2500.10 as 2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as 2,501)
  });

  useEffect(() => {
    if (status === 'idle') {
      dispatch(rootwalletActions.getAll());
    }
  }, [dispatch, status]);

  return (
    <Page colorScheme={darkMode ? 'teal' : 'blackAlpha'}>
      <Heading as="h3" textAlign={'center'} marginBottom={'1rem'}>
        MASTER WALLET BALANCES
      </Heading>
      { status === 'pending' && loading && <Loader />}
      {/* rejected */}
      {status === "rejected" && <Retry message={error} callback={() => dispatch(rootwalletActions.getAll())} />}
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        {content && content.map(i => 
        <GridItem key={i.name} marginBottom={'2'}>
          <Stat border={'1px solid teal'} padding={'5'} borderRadius={'1rem'}>
            <StatLabel>{i.name}</StatLabel>
            <StatNumber>
              {
                objectParser(i.balance) ? <div style={{ display: 'block' }}>
                    <p style={{ marginRight: '1rem' }}>Incoming: {i.balance.incoming}</p>
                    <p>Outgoing: {i.balance.outgoing}</p>
                  </div> : <> {formatter.format(i.balance)}</>
              }
            </StatNumber>
            <StatHelpText>
                {i.currencyType}
            </StatHelpText>
          </Stat>
        </GridItem>
        )}
      </Grid>
    </Page>
  );
};

export default RootWallet;
