import { rootwalletConstants } from '../constants';

import {
    request,
    failure
} from './utils'

const {
  GETALL_REQUEST,
  GETALL_SUCCESS,
  GETALL_FAILURE,
  RESET_COUNTRIES,
} = rootwalletConstants;

const initialState = {
  content: [],
  error: null,
  status: "idle",
  loading: true
}

export const rootwalletConfig = ( state = initialState, action ) => {
    switch (action.type) {
        case GETALL_REQUEST:
            return request(state);        
        
        case GETALL_FAILURE:
            return failure( state, action.payload )

        case GETALL_SUCCESS:
            const res = []
            for (const iterator of Object.keys(action.payload)) {
              //   console.log(res[iterator]);
              res.push(action.payload[iterator]);
            }
            return {
                ...state,
                error: null,
                status: 'resolved',
                loading: 'false',
                size: action.payload.size,
                content: res
            }
            
            // map the state to show the deleted user instead of loading from the api again

        case RESET_COUNTRIES:
            return initialState

        default:
            return state;
    }
}