import { Box } from "@chakra-ui/react"
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTable } from "../../hooks";
import { referralStatisticsActions } from "../../redux/actions";
import { formatCurrency, nullValue } from "../../utils/helpers";
import { Loader } from "../Loader/Loader";
import { NoData } from "../NoData/NoData";
import { Retry } from "../Retry/Retry";
import { Table } from "../Table/Table";
import { TableExt } from "../Table/TableExt";
import styles from './index.module.scss';
import { ViewReferrals } from "./ViewReferrals";


export const ReferralStatistics = () => {
  const [ activeRow, setActiveRow ] = useState({referredUsers: [], username: ''})
  const [ viewReferred, setViewReferred ] = useState(false)

  const dispatch = useDispatch()
  const {
    content,
    status,
    totalPages,
    totalElements,
    error,
    size
  } = useSelector( state => state.referralStatistics )

  const fetchMore = ( page ) => {
    dispatch( referralStatisticsActions.getAll( page ))
  }

  const {
    currentPage,
    incPage,
    decPage,
    changePage
  } = useTable( { content, totalPages, fetchMore, size })

  useEffect(() => {
    if(status === 'idle') dispatch(referralStatisticsActions.getAll())
  })
  const headers = [
    's/n',
    'Username',
    'Balance (NGN)',
    'Total Referred',
    ''
  ];

  const columns = [
    {
      key: 'username',
      render: (cell, row, index) => {
        return cell ? cell : nullValue();
      }
    },
    {
      key: 'balance',
      render: (cell, row, index) => {
        return formatCurrency(cell);
      }
    },
    {
      key: 'referred',
      render: (cell, row, index) => {
        return cell;
      }
    },
    {
      key: 'referredUsers',
      render: (cell, row, index) => {
        const handleClick = () => {
          setActiveRow(row)
          setViewReferred(true)
        }
        return(
          <p className={styles['link']} onClick={handleClick}>View Referred Users</p>
        )
      }
    }
  ]

  const tableData = content[currentPage + 1] || []

  return(
    <Box className={styles['wrapper']}>
      <ViewReferrals isOpen={viewReferred} close={() => setViewReferred(false)} data={activeRow.referredUsers} username={activeRow.username}/>
      
      {/*resolved, and no data  */ }
      { status === "resolved" && !tableData.length ? <NoData /> : null }


      {/* rejected */ }
      { 
        status === "rejected" ? <Retry message={ error } callback={ () => fetchMore( currentPage + 1 ) } /> : 

        status === 'pending' ? <Loader /> : 
        tableData.length ?
          <>
            <Table
              headers = { headers }
              columns = { columns }
              data = { tableData }
              rowClickHandler = { () => {} }
              size={ size }
              currentPage={ currentPage }
            />

            <TableExt
              size={ size }
              total={ totalElements }
              totalPages={ totalPages }
              currentPage={ currentPage }
              goTo={ changePage }
              goNext={ incPage }
              goBack={ decPage }
            />
          </>
          :
          null
      }
          
    </Box>
  )  
}