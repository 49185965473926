import React, { useEffect, useState } from 'react';
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import
{
    MdDashboard,
    MdMonetizationOn,
    MdSend,
    MdSettings

} from 'react-icons/md';
import
{
    FaCaretDown,
    FaUserCog,
    FaKey,
    FaCoins,
    FaUsersCog,
    FaBuilding,
    FaFlagCheckered,
    FaMoneyBill,
    FaUserTag,
    FaMoneyBillWave,
    FaIdCard
} from 'react-icons/fa';
import
{
    RiLogoutBoxRFill,
    RiMenuLine,
} from "react-icons/ri";

import 
{
    BsFillQuestionCircleFill,
    BsTable
} from "react-icons/bs";

import 
{
    AlertModal
} from "../index";
import { authActions } from "../../redux/actions";

import logo from "../../assets/images/logo.svg";
import { themeColors } from '../../assets/theme/colors';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


function SideBar ({ canCollapse}) 
{
    const { darkMode } = useSelector( state => state.theme)
    const styles = darkMode ? require('./SideBarDark.module.scss') : require('./SideBar.module.scss');
    
    const USER_PERMISSIONS = JSON.parse(localStorage.getItem('user'))?.payload.permissions || [];
    const role = JSON.parse(localStorage.getItem('user'))?.payload.role || []
    const navs = []
    if (USER_PERMISSIONS.includes('ViewTransactions')) {
      navs.push({
        to: '/transactions',
        name: 'transactions',
        exact: true,
        Icon: BsTable,
      });
      navs.push({
          to: '/trades',
          name: 'trades',
          exact: true,
          Icon: FaMoneyBill,
      })
      navs.push({
        to: '/transfers',
        name: 'transfers',
        exact: true,
        Icon: MdSend,
      });
    }
    if(USER_PERMISSIONS.includes('ViewUsers')) navs.push({ to: '/users', name: 'customers', exact: true, Icon: FaUserCog})

    const collapsible = []
    const commissions = []

    if(USER_PERMISSIONS.includes('ViewAdmins')) collapsible.push({ to: '/admin-users', name: 'admin users', exact: false, Icon: FaUserCog })
    if(USER_PERMISSIONS.includes('ViewRoles')) collapsible.push({ to: '/roles', name: 'access management', exact: false, Icon: FaKey })
    if(USER_PERMISSIONS.includes('ViewCurrencies')) collapsible.push({ to: '/currencies', name: 'currency configuration', exact: false, Icon: FaCoins})

    if(USER_PERMISSIONS.includes('ViewReferrals')) commissions.push({ to: '/referral-config', name: 'referral commission', exact: false, Icon: FaUsersCog })
    
    if(role === 'SuperAdmin') {
        collapsible.push({ to: '/countries', name: 'Country Configuration', exact: false, Icon: FaFlagCheckered })
        collapsible.push({ to: '/deployment', name: 'Deployment', exact: false, Icon: FaBuilding })
        collapsible.push({ to: '/rootwallet', name: 'Master Wallet Balances', exact: false, Icon: FaMoneyBill })

        commissions.push({ to: '/p2p-config', name: 'P2P Commission', exact: false, Icon: FaUserTag })
        commissions.push({ to: '/kyc-limit-config', name: 'KYC Limit', exact: false, Icon: FaIdCard})
        commissions.push({ to: '/currency-rate-config', name: 'Currency Rate', exact: false, Icon: FaMoneyBillWave })
    }

    const navItems = [
        { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard },
        ...navs,
        {
            collapsible:
            {
                name: "commissions and limits",
                Icon: MdMonetizationOn,
                isOpen: false,
                sub: commissions
            }
        },
        {
            collapsible:
            {
                name: "settings",
                Icon: MdSettings,
                isOpen: false,
                sub: collapsible
            }
        },
    ];

    const [ state, setState ] = useState( navItems.filter( o => o.collapsible ) );
    const [ open, setOpen ] = useState( true );
    const dispatch = useDispatch();
    const { pendingLogout } = useSelector( state => state.auth );

    const { pathname } = useLocation()

    useEffect(() => {
        if(pathname === '/dashboard/'){
            setOpen(true)
        }
    }, [pathname])

    const toggleSideBar = () =>
    {
        setOpen( s => !s );
    };

    const toggleNavItem = ( name ) =>
    {
        const newState = [ ...state ];
        const toBeToggled = newState.find( n => n.collapsible.name.toLowerCase() === name.toLowerCase() );
        toBeToggled.collapsible.isOpen = !toBeToggled.collapsible.isOpen;

        setState( newState );
    };

    const generateNavMenus = navlist =>
    {
        const list = [];

        navlist.forEach( ( nav, index ) =>
        {
            if ( nav.collapsible )
            {
                const name = nav.collapsible.name[ 0 ].toUpperCase() + nav.collapsible.name.substr( 1 );

                const sublinks = nav.collapsible.sub;
                const IconOuter = nav.collapsible.Icon;

                const menus = sublinks.map( ( { to, name, exact, Icon }, index ) => (
                    <li 
                        onClick={ toggleSideBar } 
                        key={ index } 
                        className={ classNames( styles.navItem, styles.subNav ) }>
                        <NavLink
                            to={ `/dashboard${ to }` }
                            activeClassName="active"
                            exact={ exact }
                        >
                            <Icon className={ styles.navIcon } />
                            <span className="">{ name }</span>
                        </NavLink>
                    </li>
                ) );

                const menuLink = (
                    <React.Fragment key={ index }>
                        <li
                            className={ styles.navItem }
                        >
                            <button

                                onClick={ () => toggleNavItem( name ) }
                                className={ styles.navBtn }>
                                <div className={ styles.pre }>
                                    <IconOuter className={ styles.navIcon } />
                                    <span >{ name }</span>
                                
                                <FaCaretDown
                                    className={
                                        classNames( styles.navCaret,
                                            {
                                                [ styles.open ]: state.find( p => p.collapsible.name.toLowerCase() === name.toLowerCase() ).collapsible.isOpen
                                            } )
                                    }

                                />
                                </div>
                            </button>
                        </li>

                        <div className={
                            classNames( styles.collapse,
                                {
                                    [ styles.open ]: state.find( p => p.collapsible.name.toLowerCase() === name.toLowerCase() ).collapsible.isOpen
                                } )
                        } >
                            { menus }
                        </div>

                    </React.Fragment>
                );

                list.push( menuLink );

            }
            else
            {
                const { to, name, exact, Icon } = nav;

                list.push(
                    <li
                        onClick={ toggleSideBar }
                        key={ index } className={ styles.navItem }>
                        <NavLink
                            to={ `/dashboard${ to }` }
                            activeClassName="active"
                            exact={ exact }
                        >
                            <Icon className={ styles.navIcon } />
                            <span className="">{ name }</span>
                        </NavLink>
                    </li>,
                );
            }
        } );

        return list;

    };

    const signOut = () =>
    {
        dispatch( authActions.logout() );
    };

    const signOutPend = () =>
    {
        dispatch( authActions.logoutStart() );
    };

    const cancelLogout = () =>
    {
        dispatch( authActions.logoutCancel() );
    };

    return (
        <>
            <nav className={ classNames( styles.sidebar,
                {
                    [ styles.open ]: open
                } ) }>

                <NavLink className={ styles.logo } to="/dashboard">
                    <img src={ logo } alt="easyshare logo" />
                </NavLink>

                <ul className={ styles.navlist }>
                    { generateNavMenus( navItems ) }

                    <li className={ styles.navItem }>
                        <button onClick={ signOutPend } className={ styles.logout }>
                            <RiLogoutBoxRFill className={ styles.navIcon } /> Sign Out
                        </button>
                    </li>
                </ul>
            </nav>
            {
                canCollapse && 
                <button
                    onClick={ toggleSideBar }
                    className={ classNames( styles.toggler,
                        {
                            [ styles.open ]: open
                        } ) }>
                    <RiMenuLine color={themeColors.deepGreen} />
                </button>
            }

            {
                pendingLogout && <AlertModal
                    msg="You are about to signout"
                    btnText="Continue"
                    icon={ <BsFillQuestionCircleFill /> }
                    proceed={ signOut }
                    cancel={ cancelLogout }
                />
            }

        </>
    );
}

export { SideBar };
