import React from 'react';

// import styles from './Charts.module.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import { MyPie, PieKeys } from '..';
import { formatCurrency } from '../../utils/helpers';
import { useSelector } from 'react-redux';

const Profit = ( { data } ) =>
{
    //data format {[currencyName]: { revenue, expenditure, profit }}[]
    const { darkMode } = useSelector( state => state.theme )

    

    const styles = darkMode ? require("./ChartsDark.module.scss") : require("./Charts.module.scss")
    const fetchCurrencyNames = () => {
        let currencies = data.map(i => i.currencyName)
        return currencies
    }

    const fetchCurrencyData = (currency) => {
        const currencyData = data.find(i => i.currencyName == currency)
        return{
            currency,
            ...currencyData
        }
    }

    const currencies = fetchCurrencyNames(data)
    const [ currencyToDisplay, setCurrencyToDisplay] = useState(fetchCurrencyData('NGN'))
    const [ revenueWidth, setRevWidth ] = useState(0)
    const [ expenditureWidth, setExpWidth ] = useState(0)


    function displayCurrency(currencyToDisplay) {
        console.log(currencyToDisplay);
        const { fee: revenue, merchantCommission, referralCommission, currency } = currencyToDisplay;
        const expenditure = merchantCommission + referralCommission
        const profit = revenue - expenditure
        return {
            revenue,
            expenditure,
            profit,
            currency
        }
    }

    const { currency, expenditure, profit, revenue } = displayCurrency(currencyToDisplay)

    useEffect(() => {
        if(revenue === expenditure){
            setRevWidth(50)
            setExpWidth(50)
          }
          else {
            setRevWidth(Math.round(( revenue / ( revenue + expenditure ) ) * 100));
            setExpWidth(100 - revenueWidth)
          }
    }, [expenditure, revenue, profit, revenueWidth])
    

    const handleChange = (event) => {
        const { value } = event.target
        setCurrencyToDisplay(fetchCurrencyData(value))
    }

    const formatCurrencyName = (currencyName) => {
        if(currencyName.includes('VC_')){
            return currencyName.split('_')[1]
        }
        else{
            return currencyName
        }
    }

    return (
        <>
            <div className={ styles.profit }>
                <div className={ styles.header }>
                    <select className={styles.select} onChange={handleChange} value={currency}>
                        {
                            currencies.map((currency, index) => (
                                <option key={index} value={currency}>{currency}</option>
                            ))
                        }
                    </select>
                    <p className={ styles.target }>
                        <span className={styles.header}>{ profit < 0 ? 'LOSS' : 'PROFIT'}:</span>  
                        <span className={styles.currencyName}>{currency}</span> 
                        {formatCurrency(Math.abs(profit)) }
                    </p>
                </div>
            </div>
            
            <div className={ styles.pieChart }>
                <div className={styles.keys}>
                    <PieKeys
                        data={[
                            { x: `Revenue: ${formatCurrency(revenue)}`, y: revenueWidth, fill: "#0a5244" },
                            { x: `Expenditure: ${formatCurrency(expenditure)}`, y: expenditureWidth, fill: "#a12424" },
                        ]}
                    />
                </div>
                <div className={styles.pie}>
                    <MyPie 
                        data={[
                                { x: "REVENUE", y: revenueWidth, fill: "#0a5244" },
                                { x: "EXPENDITURE", y: expenditureWidth, fill: "#a12424" },
                        ]}
                        radius={120}
                    />
                </div>
                
            </div>
        </>
    );
};

export { Profit };
