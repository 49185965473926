export const baseUrl =
  process.env.REACT_APP_BACKEND_URL || 'https://api-dev.easyshare.global/';
export const publicURL =
  process.env.PUBLIC_URL || 'https://admin-dev.easyshare.global/';
// export const publicURL = 'http://localhost:8000'

// const apiUrl = baseUrl + "/api/admin";
export const apiUrl = baseUrl + "/admin/v2";

function authHeader ()
{
    let user = JSON.parse( localStorage.getItem( 'user' ) );
    if ( user && user.accessToken )
    {
        return {
            'Authorization': 'Bearer ' + user.accessToken
        };

    }
    else
    {
        return {};
    }
}

function buildParams ( params )
{
    let str = Object.keys( params ).reduce( ( acc, p ) => acc = `${ acc }/${ p }=${ params[ p ] }`, "" ).substr( 1 );

    if ( str.length )
    {
        str = `?${ str }`;
    }
    return str;
}

function buildQuery ( params )
{
    let str = Object.keys( params ).reduce( ( acc, p ) => acc = `${ acc }&${ p }=${ params[ p ] }`, "" ).substr( 1 );
    if ( str.length )
    {
        str = `?${ str }`;
    }
    return str;
}

const makeRequest = async ( url, reqOptions ) =>
{
    let code;

    try 
    {
        const res = await fetch( `${ url }`, reqOptions );
        code = res.status;

        const data = await res.json();


        if ( !res.ok )
        {
            return {
                error: data?.message || "Unable to complete request"
            };
        }

        return data;
    }
    catch ( error ) 
    {

        if ( error.message === "Unexpected end of JSON input" && code <= 299 & code >= 200 )
        {
            //creating order returns 200, but no body
            return { data: "Success" };
        }

        return {
            error: error.message || "Request Failure"
        };
    }

};

const get = async ( url = "", params = {}, reqConfig =
    {
        useRoot: false,
        useFullRoot: false,
        useHeaders: false,
        useQuery: false
    } ) =>
{
    const { useFullRoot, useRoot, useQuery } = reqConfig;
    const requestOptions =
    {
        method: 'GET',
        headers: reqConfig.useHeaders ? authHeader() : {},

    };

    const endpoint = useQuery ? buildQuery( params ) : buildParams( params );
    let urlRoot = "";
    let fullUrl = "";
    if ( useFullRoot )
    {
        urlRoot = url;
        fullUrl = `${ urlRoot }${ endpoint }`;
    }
    else
    {
        urlRoot = useRoot ? baseUrl : apiUrl;
        fullUrl = `${ urlRoot }/${ url }${ endpoint }`;

    }
    const data = await makeRequest( fullUrl, requestOptions );

    return data;

};

const post = async ( url = "", body = {}, params = {}, useRoot = false, useHeader = true ) =>
{
    const token = useHeader ? authHeader() : {};

    const requestOptions =
    {
        method: 'POST',
        headers: { ...token, 'Content-Type': 'application/json' },
        body: JSON.stringify( body ),
    };

    const endpoint = buildParams( params );

    const urlRoot = useRoot ? baseUrl : apiUrl;
    const data = await makeRequest( `${ urlRoot }/${ url }${ endpoint }`, requestOptions );

    return data;
};

const del = async ( url = "", params = {}, body = {}, useRoot = false) =>
{
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify( body ),
    };

    const endpoint = buildParams( params );
    const urlRoot = useRoot ? baseUrl : apiUrl;
    const data = await makeRequest( `${ urlRoot }/${ url }${ endpoint }`, requestOptions );

    return data;
};

const put = async ( url = "", body = {}, params = {}, useRoot = false ) =>
{
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify( body ),
    };

    const endpoint = buildParams( params );
    const urlRoot = useRoot ? baseUrl : apiUrl;
    const data = await makeRequest( `${ urlRoot }/${ url }${ endpoint }`, requestOptions );

    return data;
};

const download = async (url, filename='file', params = {}, reqConfig = { useRoot: false, useFullRoot: false, useHeaders: true, useQuery: false } ) =>
    {
        const { useFullRoot, useRoot, useQuery } = reqConfig;
        const requestOptions =
        {
            method: 'GET',
            headers: reqConfig.useHeaders ? authHeader() : {},
        };

        const endpoint = useQuery ? buildQuery( params ) : buildParams( params );
        let urlRoot = "";
        let fullUrl = "";
        if ( useFullRoot )
        {
            urlRoot = url;
            fullUrl = `${ urlRoot }${ endpoint }`;
        }
        else
        {
            urlRoot = useRoot ? baseUrl : apiUrl;
            fullUrl = `${ urlRoot }/${ url }${ endpoint }`;
        }

        return new Promise(resolve => {
            fetch(fullUrl, requestOptions)
            .then(response => response.blob())
            .then( blob => {
                const fileExtension = blob.type.split('/')[1]
                if(fileExtension !== 'json'){
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `${filename}.${fileExtension}`;
                    a.click();
                    resolve({ status: 'success' })
                }
                else {
                    resolve({ status : 'error'})
                }
                
            })
            .catch( error => {
                console.log('There was an error. Check network log')
                resolve({ status : 'error'})
            })
        })
    };


export const api =
{
    get,
    post,
    put,
    del,
    download
};
