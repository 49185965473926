import { api } from '../../utils/api';
import { transactionsConstants } from '../constants';
import { dispatcher } from './utils';

const {
  GETALL_REQUEST,
  GETALL_FAILURE,
  GETALL_SUCCESS,
  PAGE_SIZE,
  RESET_TRANSACTIONS,
} = transactionsConstants;

const getAllTransactions =
  (page = 1) =>
  async (dispatch) => {
    dispatch(dispatcher(GETALL_REQUEST));

    const data = await api.get(
      'transaction',
      { page, size: PAGE_SIZE },
      { useHeaders: true, useQuery: true },
    );

    if (data.error) {
      dispatch(dispatcher(GETALL_FAILURE, data.error));
    } else {
      dispatch(dispatcher(GETALL_SUCCESS, data));
    }
  };

const searchTransaction = (searchType, searchValue) => async (dispatch) => {
  dispatch(dispatcher(GETALL_REQUEST));

  const data = await api.get(
    'transaction',
    { searchType, searchValue },
    { useHeaders: true, useQuery: true },
  );

  if (data.error) {
    dispatch(dispatcher(GETALL_FAILURE, data.error));
  } else {
    dispatch(dispatcher(GETALL_SUCCESS, data));
  }
};
const reset = () => async (dispatch) => {
  dispatch(dispatcher(RESET_TRANSACTIONS));
};

export const transactionsActions = {
  getAllTransactions,
  searchTransaction,
  reset,
};
