export const rootwalletConstants = {
    GETALL_REQUEST: 'ROOTWALLET_GETALL_REQUEST',
    GETALL_SUCCESS: 'ROOTWALLET_GETALL_SUCCESS',
    GETALL_FAILURE: 'ROOTWALLET_GETALL_FAILURE',
  
    CREATE_REQUEST: 'ROOTWALLET_CREATE_REQUEST',
    CREATE_SUCCESS: 'ROOTWALLET_CREATE_SUCCESS',
    CREATE_FAILURE: 'ROOTWALLET_CREATE_FAILURE',
    
    UPDATE_REQUEST: 'ROOTWALLET_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ROOTWALLET_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ROOTWALLET_UPDATE_FAILURE',
  
    DELETE_REQUEST: 'ROOTWALLET_DELETE_REQUEST',
    DELETE_SUCCESS: 'ROOTWALLET_DELETE_SUCCESS',
    DELETE_FAILURE: 'ROOTWALLET_DELETE_FAILURE',
  
    RESET_ROOTWALLET: "RESET_ROOTWALLET"
  }