const TRANSACTION_OPERATION = {
  RECEIVE : "RECEIVE",
  SEND : "SEND",    
  SELL :  "SELL",    
  BUY : "BUY",  
  SWAP : "SWAP",
  P2P_WITHDRAWAL: "P2P WITHDRAWAL",
  P2P_TOPUP: "P2P TOPUP"
}

const P2P_TRANSACTION_OPERATION = {
  P2P_TOPUP: 'P2P_TOPUP',
  P2P_WITHDRAWAL: 'P2P_WITHDRAWAL',
};

const CURRENCY_RATES_TYPE = {
  FIAT: 'FIAT',
}

const CURRENCY_RATES_VALUE_TYPE = {
  PERCENT: 'PERCENT',
  FIXED: 'FIXED'
}

const CURRENCY_RATES_TX_OPERATION = {
  BUY: 'BUY',
  SELL: 'SELL'
}

const TRANSACTION_STATUS = {
  PENDING : 'PENDING',
  INITIATED : 'INITIATED',
  COMPLETED : 'COMPLETED',
  ON_HOLD : 'ON HOLD',
  FAILED : 'FAILED',
}

const TRANSACTION_TYPE = {
  WALLET_TO_WALLET : 'WALLET TO WALLET',
  CRYPTO_TOP_UP : 'CRYPTO TOP-UP',
  CRYPTO_WITHDRAWAL : 'CRYPTO WITHDRAWAL',
  FEE : 'FEE',
}

const transactionsConstants = {
  PAGE_SIZE: 35,
  GETALL_REQUEST: 'TRANSACTIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'TRANSACTIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'TRANSACTIONS_GETALL_FAILURE',
  RESET_TRANSACTIONS: 'RESET_TRANSACTIONS',
};

const tradesConstant = {
  GETALL_REQUEST_ON_HOLD: 'GETALL_REQUEST_ON_HOLD',
  GETALL_FAILURE_ON_HOLD: 'GETALL_FAILURE_ON_HOLD',
  GETALL_FAILED: 'GETALL_FAILED',
  GETALL_SUCCESS_ON_HOLD: 'GETALL_SUCCESS_ON_HOLD',
  GETALL_SUCCESS: 'GETALL_SUCCESS',
  RESET_TRANSACTIONS: 'RESET_TRANSACTIONS',
  PAGE_SIZE: 35,
};

const transfersConstant = {
  GETALL_REQUEST_ON_HOLD: 'GETALL_REQUEST_ON_HOLD',
  GETALL_FAILURE_ON_HOLD: 'GETALL_FAILURE_ON_HOLD',
  GETALL_FAILED: 'GETALL_FAILED',
  GETALL_SUCCESS_ON_HOLD: 'GETALL_SUCCESS_ON_HOLD',
  GETALL_SUCCESS: 'GETALL_SUCCESS',
  RESET_TRANSACTIONS: 'RESET_TRANSACTIONS',
  PAGE_SIZE: 35,
};



export {
  TRANSACTION_OPERATION,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
  transactionsConstants,
  P2P_TRANSACTION_OPERATION,
  CURRENCY_RATES_TYPE,
  CURRENCY_RATES_TX_OPERATION,
  CURRENCY_RATES_VALUE_TYPE,
  tradesConstant,
  transfersConstant,
};